.site-nav {
    margin-bottom: 0;
    border: 0;

    .language-nav {
        .clearfix();
        padding-top: 8px;
        padding-bottom: 7px;
        background: @language-nav-background;
        float: right;
        min-width: 100px;

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            @media (min-width: @screen-sm-min) {
                float: right;
            }

            > li {
                display: inline-block;
                a {
                    color: @language-nav-link-color;
                    font-size: 1.3rem;
                }

                ul {
                    background: @language-nav-background;
                    li {
                        background: @language-nav-background;
                        &:hover {
                            background: @language-nav-hover-background;
                        }
                        a {
                            color: @language-nav-link-color;
                            &:hover {
                                color: @language-nav-link-hover-color;
                            }
                        }
                    }
                }
            }
        }


        .nav-toggler {
            height: @top-nav-height;
            width: @top-nav-height;
            border: 0;
            padding: 0;
            background: @mobile-nav-toggler-bg;
            outline: 0;
            margin-left: 10px;
            float:right;

            @media (min-width: @mobile-nav-breakpoint) {
                display: none !important;
            }

            &:hover {
                .hamburger,
                .hamburger:before,
                .hamburger:after {
                    background: @mobile-nav-toggler-active-color;
                }
            }

            body.offcanvas-open & {
                background: @mobile-nav-toggler-active-bg;
            }

            body.offcanvas-open & .hamburger {
                background-color: transparent;

                &:before, &:after {
                    top: 0;
                    background: @mobile-nav-toggler-active-color;
                }

                &:before {
                    transform: rotate(-45deg);
                }

                &:after {
                    transform: rotate(45deg);
                }
            }

            .hamburger,
            .hamburger:before,
            .hamburger:after {
                background: @brand-dark;
                border-radius: 1px;
                width: 22px;
                height: 3px;
                position: absolute;
                display: block;
                content: "";
                cursor: pointer;
                transition: all .4s;
                top: 10px;

                @media (min-width: @screen-sm-min){
                    top: 10px;
                }
                @media (max-width: @screen-sm-min){
                    top: 10px;
                }
            }

            .hamburger:before {
                top: -6px;
                margin-top: 0;
            }

            .hamburger:after {
                top: 6px;
                margin-top: 0;
            }
        }

        .dropdown {
            font-size: @font-size-small;

            > a {
                font-family: @font-family-serif;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }

        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }

    .logo-wrap {
        margin-top: 35px;

        @media (max-width: @screen-sm-max) {
            float:left;
            width: 50%;
            margin-top: 15px;
        }

        .logo {
            display: block;
            img {
                max-width: 100%;
            }
        }
    }

    .secondary-nav {
        float: right;
        @media (max-width: @screen-sm-max) {
            padding-right: 0;
        }
        .cart {
            margin-top: 13px;
            margin-right: 15px;
            font-size: 1.3rem;
            float: right;

            @media (max-width: @screen-md-max) {
                left: auto;
                right: 0;
                margin-top: 5px;
            }

            @media (min-width: @screen-md-min) {
                float: right;
            }

            > a {
                padding: 5px 0;
                display: block;
                font-family: @font-family-serif;

                &:hover, &:focus {
                    text-decoration: none;
                }

                span, i {
                    margin: 0px 1px;
                }
            }

            .dropdown-menu {
                padding: 15px;
                max-height: 500px;
                overflow-x: hidden;
                overflow-y: auto;

                @media (min-width: @screen-sm-min) {
                    white-space: nowrap;
                }

                @media (max-width: @screen-sm) {
                    table.table > tfoot > tr > th {
                        border-top: 0;
                    }
                }

                &:before, &:after {
                    left: auto;
                    right: 12px;
                }

                &:after {
                    margin-left: 0;
                    margin-right: -1px;
                }

                td {
                    font-size: 1.3rem;

                    &.v-middle {
                        vertical-align: middle;
                    }

                    .btn-remove {
                        padding: 0;
                        background: transparent;
                        margin-top: -4px;
                    }
                }
            }

            .btn-view-cart {
                padding: 10px 15px;
                text-transform: uppercase;
                i {
                    margin-left: 5px;
                }
            }

            .empty-cart {
                font-size: 1.6rem;
                display: block;
                padding: 5px;
                i {
                    margin-right: 5px;
                    font-size: 1.8rem;
                }
            }
        }

        form.search-form {
            margin-top: 10px;
            width: 260px;
            float: right;

            @media (max-width: @screen-md) {
                display:none;
            }
        }

        .search-container {
            position: relative;

            .form-control {
                padding-right: 40px;
                body.has-left-menu & {
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    box-shadow: none;
                }
                &:focus {
                    box-shadow: none;
                    border-color: @text-color;
                }
            }

            .search-submit-btn {
                .btn();
                background: transparent;
                position: absolute;
                right: 0;
                top: 0;

                &:focus, &:active {
                    box-shadow: none;
                    color: @brand-primary;
                }
            }
        }
    }

    .user-nav {
        .dropdown-menu {
            left: auto;
            right: 0;
            margin-top: 7px;
            padding: 8px 0;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
            li {
                margin-left: 25px;
            }
            &.login {
                min-width: 280px;

                li {
                    margin-right: 25px;
                }

                form {
                    padding: 5px 5px;
                }
            }
        }
        @media (max-width: @screen-sm-max) {
            margin-top: 5px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            float: right;
            @media (min-width: @screen-md-min) {
                float: right;
            }
        }

        li {
            display: block;
            float: left;

            a {
                display: block;
                padding: 7px;
                font-family: @font-family-serif;
                font-size: 1.2rem;
                transition: all .45s ease-in-out;

                &:hover, &:focus {
                    background-color: @brand-primary;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.nav-toggler {
    display: block;
    padding: 10px;
    border: 0;
    outline: 0;
    font-family: @font-family-serif;
    position: relative;
    transition: all .3s;
    color: @nav-toggler-color;

    @media (min-width: @screen-md-min) {
        display: none !important;
    }

    &:hover, &:focus {
        background: @nav-toggler-hover-background;
        color: @nav-toggler-hover-color;
        text-decoration: none;

        .icon-container {
            .hamburger,
            .hamburger:before,
            .hamburger:after {
                background: @nav-toggler-hover-color;
            }
        }
    }

    body.mobile-nav-open & {
        background: @nav-toggler-active-background;
        color: @nav-toggler-active-color;
    }

    body.mobile-nav-open & .hamburger {
        background-color: transparent;
        color: @nav-toggler-color;

        &:before, &:after {
            top: 0;
            background: #fff;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }

    .icon-container {
        display: block;
        float: left;
        margin-right: 10px;
        position: relative;

        .hamburger,
        .hamburger:before,
        .hamburger:after {
            background: @brand-dark;
            border-radius: 1px;
            width: 22px;
            height: 3px;
            position: absolute;
            display: block;
            content: "";
            cursor: pointer;
            transition: all .3s;
            top: 10px;
        }

        .hamburger:before {
            top: -6px;
            margin-top: 0;
        }

        .hamburger:after {
            top: 6px;
            margin-top: 0;
        }
    }

    .menu-text {
        margin-left: 30px;
    }
}

.main-menu {
    .clearfix();
    border-top: 1px dotted @brand-primary;
    border-bottom: 1px dotted @brand-primary;
    font-size: 1.6rem;
    @media (max-width: @screen-lg) {
        font-size: 1.4rem;
    }
    margin: 12px 0 20px 0;
    background: @main-menu-background;
    &.mega-head {
        padding-left: 10px;
        font-size: 1.8rem;
        margin-top:0;
        margin-bottom: 0;
        height: 40px;
        line-height: 40px;
    }
    @media (max-width: @screen-sm-max) {
        margin-top: 10px;
        border-bottom: 5px solid @brand-primary;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li a {
            font-family: @font-family-serif;
            display: block;
            color: @main-menu-link-color;
        }
    }

    ul.menu-items {
        .clearfix();
        display: none;
        position: relative;

        @media (min-width: @screen-md-min) {
            display: block;
        }

        > li {
            display: block;
            float: left;
            position: relative;

            &:hover, &:focus {
                > a {
                    background-color: @menu-item-hover-background;
                    color: @main-menu-link-hover-color;
                    text-decoration: none;
                }
            }

            > a {
                padding: 0 15px;
                line-height: 50px;
                transition: all .2s ease-in-out;
                color: @main-menu-link-color;
            }

            &.active {
                a {
                    background-color: @brand-primary;
                    color: @main-menu-active-link-color;
                }
            }
        }
    }

    .category-link {
        &.is-parent {
            position: static;

            > a:after {
                display: inline-block;
                content: @fa-var-angle-down;
                font-family: FontAwesome;
                margin-left: 3px;
            }

            &:hover, &:focus {
                > .mega-menu-container {
                    visibility: visible;
                    opacity: 1;
                    background: @megamenu-bg;
                    z-index: 1000;

                    a {
                        color: @megamenu-link;
                    }
                }
            }
            &.edge {
                .mega-menu-container {
                    right: 0px;
                }
            }
        }

        &.current,
        &.current-parent,
        &.current:hover,
        &.current-parent:hover {
            > a {
                color: @main-menu-active-link-color;
                background: @brand-primary;
            }

            > .mega-menu-container {
                background: @megamenu-active-hover-bg;
                a {
                    color: @megamenu-active-link;
                }
            }
        }

    }

    .mega-menu-container {
        position: absolute;
        top: 100%;
        background: @brand-primary;
        visibility: hidden;
        overflow-y: hidden;
        opacity: 0;
        transition: opacity .3s;
        padding: 0 15px;
        z-index: -1000;
        -webkit-box-shadow: 0px 7px 13px -2px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 7px 13px -2px rgba(0,0,0,0.75);
        box-shadow: 0px 7px 13px -2px rgba(0,0,0,0.75);

        > ul {
            .clearfix();
            float: left;
            width: 150px;
            li {
                ul {
                    li {
                        width: auto;
                    }
                }
            }
            > li {
                margin: 10px 0;
            }
        }


        ul {
            position: relative;

            li {
                padding-left: 10px;
                font-family: @font-primary;
                font-size: 1.1rem;

                a {
                    font-size: 1.3rem;
                    display: block;

                    &:hover, &:focus {
                        background-color: transparent;
                    }
                }

                i {
                    font-size: 1.2rem;
                    margin-left: 5px;
                }

                .submenu {
                    padding-left: 10px;
                }
            }

            .level-1 {
              display: block;
              width: 100%;
            }

            .level-2 {
                a {
                    padding-left: 10px;
                }
            }
        }
    }
}

.navbar-admin-tools {
    .navbar-brand {
        padding-top: 5px;
        small {
            font-size: 60%;
        }
        .maintenance-on {
            color: green;
            font-weight: bold;
        }
        .maintenance-off {
            color: red;
            font-weight: bold;
        }
    }
}

body.has-left-menu {
#mega-menu {
    background: @main-menu-background;

    ul {
        padding:0;
        margin: 0;
        list-style-type: none;
    }
    > ul {
        li {
            &:hover, &:active, &:focus {
                background: @megamenu-top-level-hover-background;
                color: @megamenu-top-level-hover-color;
                text-decoration: none;
            }

            a {
                padding: 10px 0 10px 25px;
                font-size: 14px;
                color: @megamenu-link-color;
                display: block;

                &:hover {
                    text-decoration: none;
                    color: @megamenu-link-hover-color;
                }
            }
            &.category-link {
                .mega-menu-container {
                    min-height: 0px;

                    ul {
                        list-style-type: none;
                        padding-right: 0px !important;
                        padding-left: 0px !important;
                        > li {
                            font-family: @font-primary;
                            font-size: 1.1rem;
                            a {
                                padding: 10px 0 10px 25px;
                                font-size: 1.3rem;
                                color: @megamenu-container-primary-link-color;
                                display: block;
                                &:hover {
                                    color: @megamenu-container-primary-link-hover-color;
                                }
                            }
                            i {
                                font-size: 1.2rem;
                                margin-left: 5px;
                            }
                        }
                    }

                    position: absolute;
                    left: 94%;
                    top: 0;
                    background: @megamenu-container-background;
                    visibility: hidden;
                    overflow-y: hidden;
                    opacity: 0;
                    transition: all .3s;
                    padding: 0px;
                    width: 60px;
                    z-index: 1000;
                    &.open {
                        visibility: visible;
                        opacity: 1;
                    }
                    ul {
                        float: left;
                        width: 145px;
                        position: relative;
                    }
                    a {
                        color: @megamenu-container-link-color;
                        &:hover {
                            color: @megamenu-container-link-hover-color;
                            background: @megamenu-container-link-hover-background;
                            text-decoration: none;
                        }
                        &.megamenu-main-link {
                            color: @megamenu-container-main-link-color;
                            background: @megamenu-container-main-link-background;
                            border-bottom: @megamenu-container-main-link-border;
                            padding: 10px 0 10px 25px;
                            text-transform: uppercase;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
}
