.category-sidebar {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .sidebar-block {
        & + .sidebar-block {
            margin-top: 10px;
        }

        &:first-child {
            margin-top: 3px;
        }

        .sidebar-block-title, h2,
        .title {
            display:block;
            cursor: text;
            text-decoration: none;
            font-size: 1.8rem;
            border-bottom: 1px dotted @brand-primary;
            font-weight: bold;
            padding: 7px 0;
            font-family: @font-family-serif;

            .collapse-icon {
                display: none;
            }
        }

        .xt-ph-row {
            margin-bottom: 10px;
        }
    }

    .sidebar-block-content,
    .collapse-wrap {
        display: block;
        padding: 10px;

        &.category-menu {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .sidebar-subtitle {
        font-family: @font-family-serif;
        font-weight: bold;
        padding-top: 10px;

        &:first-child {
            padding-top: 0;
        }
    }

    ul.sidebar-list {
        padding: 5px 0 10px 5px;
        margin: 0;

        li {
            line-height: 1.2;

            a {
                position: relative;
                font-size: @font-size-small;
                padding-left: 1em;

                &:hover, &:focus {
                    text-decoration: none;

                    &:before {
                        left: 3px;
                    }
                }

                &:before {
                    content: @fa-var-angle-right;
                    font-family: FontAwesome;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: left .15s;
                }

                span {
                    color: @text-muted;
                }
            }
        }
    }

    .category-menu {
        > ul > li > a {
            font-size: @font-size-large;
        }

        ul {
            li {
                position: relative;

                &.is-parent {
                    > a {
                        padding-right: 32px;
                    }

                    > .menu-toggler {
                        display: block;
                    }

                    ul {
                        display: none;
                    }

                    &.open {
                        > .menu-toggler:before {
                            content: "\2013"; //minus
                        }

                        ul {
                            display: block;
                        }
                    }
                }

                &.current {
                    > a {
                        color: @gray;
                    }
                }

                a {
                    display: block;
                    padding: 10px;
                    border-bottom: 1px dotted #ddd;
                    transition: all .25s ease-in-out;

                    &:hover, &:focus {
                        text-decoration: none;
                        background: @brand-lighter;
                        color: @brand-dark;
                    }
                }

                .menu-toggler {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: @brand-light;
                    width: 32px;
                    height: 32px;
                    line-height: 31px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 2.2rem;
                    transition: all .25s ease-in-out;

                    &:hover, &:focus {
                        background: @brand-lighter;
                    }

                    &:before {
                        content: "+";
                    }
                }
            }
        }

        ul ul {
            li a {
                padding: 7px 7px 7px 25px;
                font-size: 1.3rem;
            }
        }
    }
}


.product-list-actions {
    margin-bottom: 30px;

    label, .control-label {
        color: @text-muted;
        font-size: 1.3rem;
    }
    .toggle-view {
        text-align: right;
        float: right;

        @media (max-width: @screen-xs-max) {
            display: none;
        }

        .control-label {
            display: block;
            margin-bottom: 5px;
        }

        input[type="checkbox"] {
            display: none;
        }

        input[type="checkbox"] + label {
            .btn;
            position: relative;
            outline: 0 !important;
            border: 1px solid @border-color;
            background: #fff;

            .grid {
                padding: 0px 9px 0px 0px;
                color: #fff;
                position: relative;
                .transition(color 0.3s);
            }

            .list {
                padding: 0px 0px 0px 9px;
                color: @text-color;
                position: relative;
                .transition(color 0.5s);
            }

            &:before {
                content: "";
                background: @text-color;
                position: absolute;
                left: 2px;
                top: 2px;
                display: block;
                width: 35px;
                height: 28px;
                -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
            }
        }

        input[type="checkbox"]:checked + label {

            .list {
                color: #fff;
            }
            .grid {
                color: @text-color;
            }
            &:before {
                -webkit-transform: translate3d(35px,0,0);
                transform: translate3d(35px,0,0);
            }

        }
    }
}

.products-wrap {
    h1.category-title {
        font-size: @font-size-h1;
        padding-bottom: 7px;
        margin: 0;
        border-bottom: 1px dotted @brand-primary;
    }

    @media(min-width: @screen-md-min) {
        .product-list-actions {
            border-bottom: 1px dashed #ddd;
            padding: 10px 0;
        }
    }

    .top-pagination {
        text-align: center;
        padding: 10px 0 6px 0;

        .pagination {
            margin: 0;
        }
    }

    .bottom-pagination {
        text-align: center;
        padding: 10px 0;

        .pagination {
            margin: 0;
        }
    }
}
.product-list-view {
    .single-product {
        .make-sm-column(6);
        .make-md-column(4);
        @media (min-width: 500px) and (max-width: @screen-sm-max) {
            width: 50%;
            float: left;
            &:nth-child(2n+1) {
                clear: both;
            }
        }
        @media (min-width: @screen-md-min) {
            &:nth-child(3n+1) {
                clear: both;
            }
        }
    }
    &.search-results-view .single-product {
        .make-sm-column(4);
        .make-md-column(3);
        @media (max-width: @screen-sm-min) {
            &:nth-child(3n+1) {
                clear: none;
            }
        }
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(3n+1) {
                clear: both;
            }
        }
        @media (min-width: @screen-md-min) {
            &:nth-child(3n+1) {
                clear: none;
            }
            &:nth-child(4n+1) {
                clear: both;
            }
        }
    }

    &.list {
        @media (min-width: @screen-sm-min) {
            .image-wrap {
                .make-sm-column(4);
                .make-lg-column(3);
                padding: 15px;
                .image {
                    margin-bottom: 0;
                }
            }
            .detail-wrap {
                .make-sm-column(8);
                .make-lg-column(9);
                height: 100%;
                padding: 0 15px;
            }
            .single-product {
                width: 100%;
                .product-card {
                    margin-bottom: 80px;
                    text-align: left;
                    border-bottom: 1px dotted @table-border-color;
                    > a {
                        .clearfix;
                        flex: 1 1 0%;
                        height: 100%;
                        padding-bottom: 0;
                    }

                    .user-actions {
                        display: none;
                    }
                    .actions-wrap {
                        width: 25%;
                        float: left;
                        border-left: 1px dotted @table-border-color;
                        height: 100%;
                        padding: 0 15px;
                    }
                    .user-list-actions {
                        display: block;
                        .action {
                            display: block;
                            float: none;

                            .btn {
                                padding: 0;
                                border: none;
                                &:hover, &:focus {
                                    background-color: transparent;
                                    border: none;
                                }
                            }
                        }
                    }

                    .name {
                        font-size: 1.8rem;
                        width: 75%;
                        float: left;
                    }

                    .description {
                        display: block;
                    }
                    .actions .btn {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}

div.page-content div.category-image img {
    width: 100%;
}
