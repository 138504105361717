// Popup indication for products being added to basket
.cart-indicator {
    color: #fff;
    position: fixed;
    text-align: center;
    display: block;
    width: auto;
    padding: 30px;
    width: 290px;
    top: 50px;
    right: 50px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    border-radius: 2px;

    .success-msg,
    .error-msg {
        opacity: 0;
        .scale(0.5);
        .transition(all 0.2s);

        i {
            font-size: 3em;
        }

        p {
            font-size: 1.2em;
            font-weight: 400;
        }
    }

    &.in {
        z-index: 3000;
        opacity: 1;
        visibility: visible;
        .transition(all 0.3s);

        &:before {
            opacity: 1;
            visibility: visible;
            .transition(all 0.3s);
        }

        &.success {
            background-color: fade(@brand-primary, 80%);

            &:before {
                border-bottom-color: fade(@brand-success, 80%);
            }

            .success-msg {
                .scale(1);
                display: block;
                opacity: 1;
            }

            .error-msg {
                display: none;
            }
        }

        &.error {
            background-color: fade(@brand-danger, 80%);

            &:before {
                border-bottom-color: fade(@brand-danger, 80%);
            }

            .error-msg {
                display: block;
                opacity: 1;
                .scale(1);
            }

            .success-msg {
                display: none;
            }
        }
    }
}
