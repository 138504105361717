section.social-media-links {
    padding: 40px 0px;
    color: @brand-primary;
    ul {
        list-style: none;
    }
    li {
        margin: 10px;
        display: inline-block;
    }
    a {
        color: @brand-primary;
    }
}
