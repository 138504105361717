button.close-modal {
    position: absolute;
    top: -15px;
    right: 10px;
    background: @brand-dark;
    color: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    display: block;
    z-index: 2;
    border-radius: 50%;
    border: 0;
}

.modal-dialog {
    margin: 30px auto;
    width: @modal-sm;
    padding: 0px 25px;
    @media (min-width: @screen-md-min) {
        width: @modal-md;
        margin: 50px auto;
    }
}

.modal-content {
    box-shadow: none;
}

.product-preview-modal {
    .modal-body {
        padding: 45px 30px;
    }
    img {
        width: 100%;
        height: auto;
    }
    .preview-image {
        .make-sm-column(5);
        .make-md-column(6);
        @media (min-width: @screen-sm-min) {
            padding-right: 30px;
        }
    }
    .labels {
        position: absolute;
        z-index: 2;
        left: 15px;
        top: 0;
        .sale-badge {
            display: inline-block;
            width: 65px;
            height: 65px;
            line-height: 65px;
            margin-right: 7px;
            background: @brand-danger;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            font-size: 2.1rem;
            font-family: @font-headline;
        }
    }
    .preview-details {
        .make-sm-column(7);
        .make-md-column(6);
        @media (min-width: @screen-sm-min) {
            padding-left: 30px;
        }
        .amount .input-group {
            width: 1%;
            input {
                width: 170px;
            }
        }
        .price-line {
            color: @brand-primary;
            font-size: 2.5rem;
        }
    }
    .btn-primary {
        margin-top: 20px;
    }
    .btn-default {
        border: none;
        color: @brand-dark;
        background-color: @brand-lighter;
        &:hover {
            background-color: @brand-light;
        }
    }
}
