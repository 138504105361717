.carousel {

    .carousel-control {
        background-image: none !important;
        background-repeat: no-repeat !important;
        position: absolute;
        top: 50%;
        margin-top: -18px;
        text-align: center;
        width: 36px;
        height: 36px;
        line-height: 36px;
        color: @gray-dark;
        text-shadow: none;
        opacity: 0.6;

        @media (max-width: @screen-xs-max) {
            display: none;
        }

        &.left {
            left: 15px;
        }
        &.right {
            right: 15px;
        }
        span {
            font-size: 2rem;
            line-height: 36px;
            margin-top: 0;
            top: 0;
        }
    }
}

.carousel-section {
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 0px 30px;
    + .carousel-section {
        margin-top: 50px;
    }
    .owl-stage-outer {
        z-index: 2;
    }
    .owl-nav {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        font-size: 4rem;
        color: @text-muted;

        .owl-prev, .owl-next {
            .transition(color 0.25s);
            &:hover, &:focus {
                color: @brand-primary;
            }
            &.disabled {
                cursor: default;
                opacity: 0.3;
            }
        }

        .owl-prev {
            margin-left: -30px;
            float: left;
        }
        .owl-next {
            float: right;
            margin-right: -30px;
        }
    }
    .owl-dots {
        display: none;
    }
}

.carousel-indicators {
    bottom: 0 !important;
}

section.carousel-banner-section {
    margin-top: 0px;
    padding: 0 0px;

    .title-bar {
        display: none;
    }
}

.carousel-plugin {
    .fa {
        font-size: 30px;
        z-index: 10;
        position: absolute;
        top: 50%;
        margin-top: -15px;
    }
    .fa-angle-left {
        left: -25px;
        z-index: 1000;
    }
    .fa-angle-right {
        right: -25px;
    }

    &.one {
        .fa-angle-left {
            left: 40px;
        }
        .fa-angle-right {
            right: 40px;
        }
        .owl-dots {
            position: absolute;
            bottom: 0 !important;
            left: 50%;
            z-index: 15;
            width: 60%;
            margin-left: -30%;
            padding-left: 0;
            list-style: none;
            text-align: center;
        }
        .owl-dot {
            background: transparent;
            border-radius: 10px;
            border: 1px solid #6f6f6f;
            display: inline-block;
            height: 10px;
            margin: 1px;
            width: 10px;
            &.active {
                margin: 0;
                width:  12px;
                height: 12px;
                background-color: #6f6f6f;
            }
        }
    }
}

.carousel-banner-section {
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 0 30px;
}
