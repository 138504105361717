body.admin-tools-visible {
    padding-top: 50px;

    .site-nav {
        margin-top: 0;
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -(@grid-gutter-width / 2);
    margin-right: -(@grid-gutter-width / 2);

    &.align-items-center {
        align-items: center;
    }

    .col {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-left: @grid-gutter-width / 2;
        padding-right: @grid-gutter-width / 2;
        flex-basis: 0;
        max-width: 100%;
        flex-grow: 1;
    }
}

.offcanvas-nav {
    transform: translate3d(-100%, 0, 0);
    right: auto;
    left: 0;
}

.page-header {
    margin-top: 5px;
}

.top-bar,
.bottom-bar {
    text-transform: uppercase;
    font-weight: bold;
    font-family: @font-headline;

    p {
        margin: 0;
    }

    .xt-ph-edit {
        margin-bottom: 0;
    }

    .xt-ph-row {
        .flex-row;
        .flex-row.align-items-center;
    }

    .xt-ph-cell {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.top-bar {
    background: #000;
    border-bottom: 1px solid rgba(255,255,255,0.25);
    color: #fff;

    @media (max-width: @screen-sm-max) {
        display: none;
    }
}

.bottom-bar {
    background: @brand-lighter;
    border-bottom: 2px solid #fff;
    color: @gray;

    .xt-ph-cell {
        padding-top: 15px;
        padding-bottom: 15px;

        @media (max-width: 499px) {
            width: 100%;
            display: block;
        }

        @media (min-width: 500px) and (max-width: @screen-sm-max) {
            width: 50%;
            float: left;
        }
    }
}

.site-nav {
    background: @brand-dark;
    position: relative;
    z-index: 100;

    @media (max-width: @screen-sm-max) {
        margin-bottom: 30px;
    }

    .logo-wrap {
        margin: 0;
        order: 1;

        @media (max-width: @screen-sm-max) {
            margin-top: 0px;
        }

        .logo {
            padding: 30px 0;
            float: left;

            @media (max-width: @screen-sm-max) {
                padding: 20px 0;
            }

            @media (min-width: @screen-md-min) {
                &:before {
                    content: "";
                    background: radial-gradient(circle at center, rgba(255,255,255,0.09) 30%, transparent 100%);
                    position: absolute;
                    left: -25px;
                    width: 300px;
                    top: 0;
                    bottom: 0;
                    z-index: 0;
                }
            }

            img {
                max-height: 45px;
                position: relative;
                z-index: 1;
            }
        }
    }

    .dropdown-menu {
        li {
            width: 100%;
            margin: 0 !important;
            padding-left: 25px;
            padding-right: 25px;
        }

        a {
            margin: 0 auto;
            text-align: center;
        }

        .divider {
            display: block;
            height: 1px;
            background: @brand-lighter;
            margin: 10px 0 !important;
        }
    }

    .language-nav {
        display: none;
    }

    .secondary-nav {
        order: 3;

        @media (max-width: @screen-sm-max) {
            flex-grow: 1;
            flex-basis: 100%;
            max-width: 100%;
            padding: 0px 15px 7px 15px;

            .nav-border {
                height: 1px;
                width: 100%;
                border-top: 1px solid rgba(255,255,255,0.15);
                display: block;
                margin-bottom: 7px;
            }

            .basket-wrap {
                float: left;
            }

            .user-nav {
                float: right;
            }
        }

        a.dropdown-toggle,
        .user-nav > ul > li > a {
            color: #fff;
        }

        form.search-form {
            @media (max-width: @screen-md-max) {
                margin-top: 5px;
            }
        }

        #navigation-basket-partial {
            padding-left: 0;

            @media (max-width: @screen-sm-max) {
                margin-bottom: 0;
            }

            .basket-total-price {
                @media (max-width: @screen-xs-max) {
                    display: none;
                }
            }
        }

        .dropdown.cart {
            @media (max-width: @screen-sm-max) {
                margin-top: 0 !important;
                margin-right: 0;

                .dropdown-menu.cart {
                    left: 0;
                    right: auto;
                    min-width: 240px;
                }
            }

            .dropdown-toggle {
                @media (min-width: @screen-md-min) {
                    padding: 5px;
                    border: 1px solid rgba(255,255,255,0.2);
                }
            }
        }

        .user-nav {
            @media (max-width: @screen-sm-max) {
                margin-top: 0;
            }
        }
    }

    .nav-toggler {
        order: 2;
        margin-right: 10px;
        margin-left: 20px;
        padding: 0;
        width: 45px;
        height: 45px;

        &:hover, &:focus {
            background: transparent;
        }

        .icon-container {
            margin: 0;
            top: 10px;
            padding-left: 10px;
        }

        .hamburger,
        .hamburger::before,
        .hamburger::after {
            background: #fff;
        }
    }
}

.main-menu {
    margin: 0 0 25px 0;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

    @media (max-width: @screen-sm-max) {
        display: none;
    }
}

.main-menu .category-link.current > a,
.main-menu .category-link.current-parent > a,
.main-menu .category-link.current:hover > a,
.main-menu .category-link.current-parent:hover > a {
    background: @brand-gold;
}

.main-menu ul.menu-items > li:hover > a, .main-menu ul.menu-items > li:focus > a {
    background: lighten(@brand-gold, 25%);
}

footer {
    margin-top: 75px;

    h2 {
        border-color: rgba(0,0,0,0.1);
    }
}

.xt-ph-cell {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 1px;
}

.title-bar {
    padding: 10px 0;
    margin-top: 0;
    margin-bottom: 30px;

    &:before {
        display: none;
    }

    &.light {
        border-bottom: 1px dotted @brand-gold;

        h2 {
            font-size: @font-size-h2;
            text-transform: none;
            font-weight: 600;
        }
    }
}

.category-sidebar .category-menu ul li a {
    border-color: @brand-light;
}

.category-sidebar .category-menu ul ul ul li a {
    padding-left: 38px;
    font-size: 90%;
}

.category-sidebar .category-menu ul li.current > a {
    color: @brand-gold;
    font-weight: bold;
}

.category-sidebar .category-menu ul li.is-parent.open {
    ul {
        display: none;
    }

    > ul {
        display: block;
    }
}

.sidebar-filters .block-title {
    color: @text-muted;
}

.products-wrap .product-list-actions {
    border-bottom: 0 !important;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 0;
        height: 1px;
        border-bottom: 1px dotted @border-color;
    }
}

.sidebar-filters .collapsible-content {
    overflow: hidden;
    transition: height .25s;
}

.category-filter-display-more-wrap {
    display: none;
}

.sidebar-filters .filters {
    padding: 0 10px;
}
