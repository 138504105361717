.lines {
    .form-group {
        margin-bottom: 0;
    }

    .list-group-item {
        padding: 0px 15px 0px 15px;

        button.delete-line {
            position: absolute;
            top: 5px;
            right: 10px;
            border: 0;
            background: transparent;
            @media (min-width: @screen-sm) and (max-width: @screen-md-min) {
                right: 0;
            }
        }
    }

    .cells {
        @media (min-width: @screen-sm-min) {
            display: flex;
        }
    }

    .line-cell {
        padding: 8px 8px;
        display: inline-block;
        width: 100%;

        &.product-image {
            min-width: 100px;
            width: 116px;

            div {
                width: 100px;
            }

            @media (max-width: @screen-md-min) {
                display: block;
                margin: 0 auto;
            }
        }

        &.name, &.text {
            @media (min-width: @screen-sm-min) {
                min-width: 250px;
            }
            @media (min-width: @screen-md-min) {
                min-width: 370px;
            }
            @media (min-width: @screen-lg-min) {
                min-width: 450px;
            }
        }

        &.price h4, &.name h4, &.quantity div {
            margin-top: 6px;
            margin-bottom: 6px;
        }

        &.lines-total div {
            min-width: 150px;

            @media (max-width: @screen-md-min) {
                float: right;
            }
        }

        @media (min-width: @screen-sm-min) {
            flex: 1 auto;
            align-self: flex-start;
        }

        label {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 5px;
        }

        a {
            display: block;
            line-height: 1.2;
            font-weight: 400;
        }
    }
}

.basket-items {
    margin-bottom: 70px;

    .basket-unorderable-lines {
        margin-top: 50px;

        .single-item {
            background: inherit;
            border: 1px solid #dfe2e4;
        }
    }

    .basket-summary {
        .total-price-label {
            line-height: 2.8rem;
        }
        .total-price {
            font-size: 2.0rem;
        }
    }
}

.basket-discount-code {
    margin-top: 30px;

    a {
        color: @text-muted;
        text-transform: uppercase;
        font-size: 1.3rem;
    }
    .input-group {
        margin-top: 15px;
    }
}

.cart-actions {
    margin-bottom: 20px;
}

.continue-shopping {
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.btn-checkout {
    text-transform: uppercase;
    padding: 10px 15px;
    .fa {
        font-size: 1.6rem;
    }
    .fa-lock {
        margin-right: 6px;
    }
    .fa-angle-double-right {
        margin-left: 6px;
    }
}

.btn-save-cart {
    text-transform: uppercase;
    padding: 7px 12px;
    border: 3px solid @brand-primary;
    background-color: transparent;
    color: @brand-primary;
}

.input-save-cart {
    margin-top: 15px;
}

#save-cart-status {
    p {
        font-size: 1.5rem;
        margin-top: 5px;
    }
}

.checkout-provider-wrap {
    position: relative;
    min-height: 80px;
    padding: 15px 0;

    & + .checkout-provider-wrap {
        border-top: 1px solid @border-color;
        padding-top: 30px;
        margin-top: 15px;

        @media (min-width: @screen-sm-min) {
            .checkout-service-provider {
                top: 30px;
            }
        }
    }
}

.checkout-service-provider {
    margin-bottom: 25px;

    @media (min-width: @screen-sm-min) {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 200px;
        margin: 0;
    }

    h3 {
        margin: 0;
        font-size: @font-size-base;
        font-weight: bold;
        text-transform: uppercase;
        color: @text-muted;
    }

    img {
        max-height: 50px;
        max-width: 200px;
        width: auto;
        height: auto;
    }
}

.checkout-method-radio {
    margin: 0;

    @media (min-width: @screen-sm-min) {
        padding-left: 250px;
    }

    & + .checkout-method-radio {
        margin-top: 20px;
    }

    label {
        padding-left: 30px;
        display: table;
        width: 100%;

        input {
            margin-left: -30px !important;
        }

        .logo {
            display: table-cell;
            padding-right: 15px;
            vertical-align: top;
            width: 75px;

            img {
                max-height: 30px;
                max-width: 60px;
                height: auto;
                width: auto;
            }
        }

        .label-text {
            display: table-cell;
            vertical-align: top;
            font-size: 1.6rem;

            .price {
                font-weight: bold;
                color: @brand-primary;

                &:before {
                    content: " - ";
                    color: @text-color;
                    font-weight: normal;
                }
            }

            .description {
                font-size: 1.3rem;
                color: @text-muted;
            }
        }
    }
}

.package-children {
    list-style-type: none;
    padding-left: 0;
}

