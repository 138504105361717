
.offcanvas-nav {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: @zindex-navbar-fixed + 1;
    width: @mobile-nav-width;
    background: @brand-lighter;
    overflow-y: auto;

    -webkit-transition: transform .4s;
    -moz-transition: transform .4s;
    -ms-transition: transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s;

    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);

    @media (max-width: (@mobile-nav-breakpoint - 1)) {
        display: block;
    }

    body.offcanvas-open & {
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: none;
    }

    > ul {
        margin-bottom: 15px;
        display: block;
    }

    li {
        display: block;
        background: @brand-lighter;
        position: relative;

        &.mega-menu-title {
            display: none;
        }

        &.level-1 {
            background: lighten(@brand-lighter, 3%);

            > .toggle-icon {
                background: lighten(@brand-lighter, 3%);

                &:after {
                    background: lighten(@brand-lighter, 3%);
                }
            }
        }

        &.level-2 {
            background: lighten(@brand-lighter, 5%);

            > .toggle-icon {
                background: lighten(@brand-lighter, 5%);

                &:after {
                    background: lighten(@brand-lighter, 5%);
                }
            }
        }

        &.level-3 {
            background: @mobile-nav-link-level-3-bg;
        }

        &.is-parent {
            > a {
                padding-right: 44px;
            }
        }

        &.open {
            > ul {
                display: block;
            }

            > .toggle-icon:before {
                content: "\2014"; //em dash
            }
        }

        a {
            display: block;
            padding: 12px 15px;
            color: @brand-dark;
            transition: all .15s;
            font-size: 1.4rem;
            text-decoration: none;

            &.current {
                color: @mobile-nav-link-active-color;
                background-color: @mobile-nav-link-active-bg;
            }
        }

        .toggle-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 44px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            background: transparent;
            cursor: pointer;
            z-index: 2;
            display: block;

            &:before {
                content: "+";
                font-family: FontAwesome;
                z-index: 2;
                color: @brand-primary;
                position: relative;
                display: block;
            }

            &:after {
                content: "";
                position: absolute;
                left: 7px;
                top: 7px;
                bottom: 7px;
                right: 7px;
                border-radius: @border-radius-base;
                background: @brand-lighter;
                z-index: 1;
            }
        }
    }

    ul li > ul li a {
        padding-left: (15px + 15);
    }

    ul ul li > ul li a {
        padding-left: (15px + 30);
    }
}

.offcanvas-search {
    padding: 15px 15px 20px 15px;
    margin-bottom: 15px;
    background: @brand-light;

    &-wrap {
        position: relative;
    }

    &-input {
        width: 100%;
        background: transparent;
        color: @text-color;
        border: 0;
        border-bottom: 1px solid @brand-dark;
        padding: 6px 30px 6px 6px;
        font-size: 1.4rem;
        transition: border-color .2s;

        &:focus, &:active {
            border-bottom-color: @mobile-search-border-focus-color;
        }
    }

    &-submit {
        position: absolute;
        right: 5px;
        top: 5px;
        background: transparent;
        color: @brand-primary;
        padding: 0;

        &:hover, &:focus {
            color: @mobile-search-icon-hover-color;
        }
    }
}
