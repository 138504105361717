footer {
    margin-top: 30px;

    section.newsletter-lift {
        padding: 0px;
        background: transparent;

        h2 {
            text-align: left;
        }

        p {
            text-align: left;
            font-size: 14px;
            font-weight: normal;
        }

        .form-group {
            margin: 0 auto;
            width: 100% !important;

            .form-control {
                height: 30px;
            }
        }
    }
    h2 {
        text-transform: uppercase;
        font-size: 18px;
        border-bottom: 1px solid lighten(@gray, 40%);
        padding-bottom: 6px;
        color: @brand-primary;
    }
    ul {
        padding: 0;
        list-style: none;
        font-size: 90%;
    }

    @media (max-width: 480px) {
        .col-xxs-12 {
            width: 100%;
        }
    }

    .footer-top {
        padding: 30px 0px;
        background: @footer-bg;

        a {
            color: @text-color;
            &:hover, &:focus {
                text-decoration: underline;
                color: @brand-primary;
            }
        }

        h4 {
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 8px;
            border-bottom: 1px solid lighten(@gray, 40%);
            padding-bottom: 5px;
            color: @brand-primary;
        }

        img {
            height: 191px;
            max-width: 210px;
        }

        .social-media-links {
            h2 {
                padding-left: 15px;
            }
            p {
                padding-left: 15px;
            }
            ul {
                list-style: none;
                padding-left: 5px;
            }
            li {
                margin: 10px;
                display: inline-block;
            }
            i {
                padding-left: -10px;
            }
        }
    }

    .footer-bottom {
        padding: 8px 0px;
        background: @brand-dark;
        text-align: center;

        ul {
            margin: 0;
            li {
                color: rgba(255,255,255,0.75);
            }
        }

        p.powered {
            padding-top: 5px;
            margin: 0;
            font-size: 85%;
            color: rgba(255, 255, 255, 0.75);

            a {
                color: rgba(255, 255, 255, 0.75);
                text-decoration: none;
            }
        }
    }
}
