.product-image {
    @media (max-width: @screen-sm-min) {
        padding-right: 30px;
        padding-left: 30px;
    }

    .labels {
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 5px;
        .label-sale, .label-new {
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 55px;
            margin-right: 7px;
            background: @brand-primary;
            color: #fff;
            border-radius: 50%;
            text-align: center;
            font-size: 1.6rem;
            font-family: @font-headline;
            transition: all .15s;
        }
        .label-sale {
            background: @label-sale-background;
            color: @label-sale-color;
        }
        .label-new {
            background: @label-new-background;
            color: @label-new-color;
        }
    }
    .discount {
        position: absolute;
        right: 20px;
        top: 5px;
        z-index: 10;
    }
    .product-carousel {
        margin-bottom: 10px;
        max-height:390px;

        &:hover {
            .carousel-arrow {
                opacity: 1;
            }
        }
        &.fade {
            opacity: 1;

            .item {
                .transition(opacity 0.5s);
                left: 0 !important;
                opacity: 0;
                top: 0;
                position: absolute;
                width: 100%;
                display: block !important;
                z-index: 1;
                padding: 0;
                height: 100%;
                max-height:390px;
                &:first-child {
                    top: auto;
                    position: relative;
                    padding: 0;
                }
                &.active {
                    height: 100%;
                    opacity: 1;
                    .transition(opacity 0.5s ease-in-out);
                    z-index:2;
                }
                .image {
                    background-position: 50%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    &:before {
                        display: block;
                        content: "";
                        padding-top:390px;
                    }
                }
            }
        }
    }

    .wrap-thumbnails {
        padding: 0px 30px;

        .owl-item {
            .thumbnail {
                margin-bottom: 2px;
                margin-right: 2px;
            }
            .image {
                background-position: 50%;
                background-size: contain;
                background-repeat: no-repeat;
                &:before {
                    display: block;
                    content: "";
                    padding-top: 100%;
                }
            }
        }
        .owl-stage-outer {
            z-index: 2;
        }
        .carousel-thumbnails {
            position: relative;
        }
        .owl-nav {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            .owl-prev, .owl-next {
                position: absolute;
                top: 0;
                text-align: center;
                height: 100%;
                padding: 0px 5px;
                display: flex;
                flex-flow: wrap;
                i {
                    align-self: center;
                }
            }
            .owl-prev {
                left: 0;
                margin-left: -30px;
            }
            .owl-next {
                right: 0;
                margin-right: -30px;
            }
        }
    }
}
.product-info {
    margin-bottom: 25px;
    @media (min-width: @screen-md-min) {
        padding-left: 30px;
    }
    .review {
        margin-bottom: 15px;
        font-size: 0.8em;
        p {
            margin-bottom: 5px;
        }
        i {
            font-size: 1.8em;
        }
        .gray {
            color: @gray-light;
        }
        .yellow {
            color: #ffdd17;
        }
        .text {
            padding-left: 3px;
        }
    }
    .icons {
        font-size: 0.8em;
        i {
            color: @gray-light;
            &:hover {
                color: @brand-primary;
            }
        }
        i.fa-circle {
            color: @brand-light;
        }
        p {
            margin-bottom: 5px;
        }
    }
}
.product-tabs {
    margin-top: 30px;
    i {
        margin-right: 5px;
    }
    .tab-content {
        padding-top: 25px;
    }
}

.product-main {
    #product-price-section {
        .amount .input-group {
            width: 1%;
            input {
                width: 130px;
            }
        }
        .price h2 {
            margin-bottom: 0;
        }
        .btn-add-to-cart {
            margin-top: 15px;
        }
    }
}

form.add-to-basket {
    margin-bottom: 15px;
}
